<template>
  <b-modal :id="cancelBookingModal" title="BootstrapVue" hide-footer>
    <template #modal-title>Huỷ lịch họp</template>
    <p v-if="role === 'admin'" class="mb-2">
      Bạn đang huỷ lịch họp của
      <b class="text-primary">{{ bookingInfo["user.fullname"] || username }}</b>
      với tư cách
      <b class="text-danger">quản trị viên.</b>
    </p>

    <p v-else class="mb-2">Bạn đang huỷ lịch họp bạn đã tạo.</p>

    <p>
      <b class="text-primary">{{ bookingInfo?.roomName }}</b>
      lúc

      <b class="text-primary">{{ startingTime }}.</b>
    </p>

    <b-form class="form mt-8" @submit.stop.prevent="onSubmit">
      <b-form-group>
        <b-form-input
          class="form-control form-control-solid h-auto"
          id="example-input-1"
          name="example-input-1"
          v-model="$v.form.cancelReason.$model"
          :state="validateState('cancelReason')"
          aria-describedby="input-1-live-feedback"
          placeholder="Vui lòng điền lý do trước khi huỷ cuộc họp"
          size="sm"
        ></b-form-input>
      </b-form-group>

      <b-button
        type="submit"
        ref="kt_cancel_booking_submit"
        class="w-100 d-flex justify-content-between align-items-center"
        size="sm"
        :variant="cancelButtonVariant"
        :disabled="!cancelable"
      >
        <b>Huỷ lịch họp</b>
        <i class="fa fa-ban p-0"></i>
      </b-button>
    </b-form>
  </b-modal>
</template>

<script>
import moment from "moment";
import ApiService from "@/core/services/api.service";
import { required } from "vuelidate/lib/validators";
import { RELOAD_COUNT } from "@/core/services/store/store";
import { validationMixin } from "vuelidate";
import { splitEmail } from "@/core/helpers/stringHandling";
import {
  errorNotification,
  successNotification,
} from "@/core/helpers/notification";
import { mapGetters } from "vuex";

export default {
  name: "cancel-booking-modal",
  props: ["bookingID", "bookingInfo", "role", "active"],
  mixins: [validationMixin],

  data() {
    return {
      // cancelBookingModal:'',
      cancelable: false,
      cancelButtonVariant: "",
      form: {
        cancelReason: "",
      },
    };
  },
  validations: {
    form: {
      cancelReason: {
        required,
      },
    },
  },

  computed: {
    ...mapGetters(["currentUser"]),
    username() {
      const username = splitEmail(this.bookingInfo.email);

      if (username == null) {
        return this.bookingInfo.email;
      }
      return splitEmail(this.bookingInfo.email)[1];
    },
    startingTime() {
      if (this.bookingInfo) {
        return moment(this.bookingInfo.startingTime).format(
          "HH:mm, dddd DD/MM/YYYY"
        );
      }
      return null;
    },
    cancelBookingModal() {
      return this.active !== true
        ? `cancel-booking-modal-${this.bookingID}`
        : `cancel-booking-modal-admin-${this.bookingID}`;
    },
  },

  watch: {
    bookingInfo() {
      this.getUserListInMeeting();
    },

    "form.cancelReason": {
      handler(cancelReason) {
        if (cancelReason.trim()) {
          this.cancelable = true;
          this.cancelButtonVariant = "danger";
        } else {
          this.cancelable = false;
          this.cancelButtonVariant = "";
        }
      },
    },
  },

  methods: {
    async getUserListInMeeting() {
      return await ApiService.get(
        "/meeting/listOfUserInTheMeeting",
        this.bookingInfo.id
      );
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        cancelReason: "",
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // await ApiService.delete(`/bookinginfo/deletedEventToGoogleCalendar/${this.bookingID}`);

      const cancelReason = this.$v.form.cancelReason.$model;
      await ApiService.put("/meeting/cancelBooking", {
        id: this.bookingID,
        reason: cancelReason,
      })
        .then(async () => {
          successNotification("Huỷ lịch họp thành công");
          this.$store.dispatch(RELOAD_COUNT);
          this.$bvModal.hide(`cancel-booking-modal-${this.bookingID}`);

          ApiService.post("/cancelMeetingNotify", {
            bookingId: this.bookingID,
          });

          const { data } = await this.getUserListInMeeting();
          const userArray = data.result.map((user) => user.id);

          await ApiService.delete(
            `/bookinginfo/deletedEventToGoogleCalendar/${this.bookingID}`
          )
            .then(() => {})
            .catch(() => {});

          if (userArray.length) {
            await ApiService.post("/bookinginfo/sendEmail", {
              userArray,
              bookingId: this.bookingInfo.id,
              userIdLogin: this.currentUser.id,
              user: this.bookingInfo.email,
            });
          }
        })
        .catch(({ response }) => {
          return errorNotification(response.data.message);
        });

      // await ApiService.delete(
      //   `/bookinginfo/deletedEventToGoogleCalendar/${this.bookingID}`
      // );
    },
  },
};
</script>

<style></style>
