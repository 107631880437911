<template>
  <b-card no-body>
    <b-tabs v-model="activeTab" class="card-header" pills card>
      <b-tab title="Danh Sách Tất Cả Cuộc Họp" active>
        <ListOfMeeting
          v-bind:activeTab="activeTab"
          v-bind:currentUser="currentUser"
        />
      </b-tab>
      <b-tab
        v-if="currentUser.role === 'admin'"
        title="Danh Sách Cuộc Họp Được Mời"
      >
        <ListOfMeetingsYouAttend
          v-bind:activeTab="activeTab"
          v-bind:currentUser="currentUser"
        />
      </b-tab>
    </b-tabs>
  </b-card>
</template>
<script>
import ListOfMeeting from "@/view/content/admin/ListOfMeeting.vue";
import ListOfMeetingsYouAttend from "../content/admin/ListOfMeetingsYouAttend.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      activeTab: null,
    };
  },
  methods: {},
  components: {
    ListOfMeeting,
    ListOfMeetingsYouAttend,
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>
<style scope>
.card-header {
  margin-left: 15px;
}
</style>
