<template>
  <div>
    <b-card class="max-height">
      <h5>Tìm Kiếm:</h5>
      <div class="d-flex">
        <b-form-input
          style="width: 30%"
          type="search"
          v-model="searchText"
          @input="onInput"
          placeholder="Nhập tên người tạo cuộc họp..."
        ></b-form-input>

        <b-form-select
          @change="handleChangeSelectRoom"
          class="ml-4"
          style="width: 20%"
          v-model="roomSelected"
          :options="roomOptions"
        ></b-form-select>

        <b-form-select
          @change="handleChangeSelectStatus"
          class="ml-4"
          style="width: 20%"
          v-model="statusSelected"
          :options="statusOptions"
        ></b-form-select>

        <b-form-select
          @change="handleChangeSelectedDate"
          class="ml-4"
          style="width: 20%"
          v-model="dateSelected"
          :options="dateOptions"
        ></b-form-select>
      </div>

      <b-tabs pills card>
        <b-table striped hover :items="meetingList" :fields="visibleFilter">
          <template v-slot:cell(deleted)="row">
            <div class="text-center" v-if="row.item.deleted === false">
              còn hoạt động
            </div>
            <div class="text-center" v-else>đã xóa</div>
          </template>

          <template v-slot:cell(departmentId)="row">
            <div class="text-center">{{ row.item.departmentName }}</div>
          </template>

          <template v-slot:cell(date)="row">
            <div class="text-center">{{ row.item.date }}</div>
          </template>

          <template v-slot:cell(startingHour)="row">
            <div class="text-center">{{ row.item.startingHour }}</div>
          </template>

          <template v-slot:cell(endingHour)="row">
            <div class="text-center">{{ row.item.endingHour }}</div>
          </template>

          <template v-slot:cell(meetingTitle)="row">
            <div class="text-center" v-if="row.item.meetingTitle">
              {{
                row.item.meetingTitle.length > 30
                  ? row.item.meetingTitle.substring(0, 30) + "..."
                  : row.item.meetingTitle
              }}
            </div>

            <div class="text-center" v-else>
              <p>không có tiêu đề</p>
            </div>
          </template>

          <template v-slot:cell(status)="row">
            <div class="text-center">
              <b-badge v-if="row.item.status === 'comingUp'" variant="primary"
                >Sắp diễn ra</b-badge
              >
              <b-badge v-if="row.item.status === 'done'" variant="success"
                >Đã xong</b-badge
              >
              <b-badge v-if="row.item.status === 'cancel'" variant="danger"
                >Đã Hủy</b-badge
              >
            </div>
          </template>

          <template v-slot:cell(seats)="row">
            <p class="text-center">{{ row.item.seats }}</p>
          </template>

          <template v-slot:cell(fullname)="row">
            <p
              class="text-center"
              :class="{ 'text-primary': row.item.userId === currentUser.id }"
            >
              {{ row.item.fullname }}
            </p>
          </template>

          <template v-slot:cell(action)="row">
            <div class="d-flex align-items-center justify-content-center">
              <router-link :to="`/readMeetingDetails/${row.item.id}`">
                <div
                  class="mr-3 cursor_pointer"
                  v-b-tooltip.hover
                  title="Xem chi tiết"
                >
                  <i
                    class="fa fa-eye"
                    aria-hidden="true"
                    style="color: #8950fc"
                  ></i>
                </div>
              </router-link>
              <!--  btn xóa -->
              <div
                class="cursor_pointer"
                v-if="
                  (row.item.userId === currentUser.id &&
                    row.item.status === 'comingUp') ||
                  (currentUser.role === 'admin' &&
                    row.item.status === 'comingUp')
                "
              >
                <i
                  class="fa fa-trash text-danger"
                  v-b-modal:[`cancel-booking-modal-${row.item.id}`]
                  v-on:click="handleDeleted(row)"
                ></i>
                <CancelBookingModal
                  :bookingInfo="row.item"
                  :bookingID="row.item.id"
                  :role="currentUser.role"
                />
              </div>
            </div>
          </template>
        </b-table>

        <!-- phan trang -->
        <b-pagination
          class="justify-content-end position-absolute r-25 b-14"
          @change="handldeChangeCurrentPage"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import debounce from "lodash/debounce";
import moment from "moment";
import ApiService from "@/core/services/api.service.js";
import CancelBookingModal from "../modals/CancelBookingModal.vue";
import { mapGetters } from "vuex";
import { parseJwt } from "@/core/services/jwt.service";
export default {
  data() {
    return {
      role: "",
      userId: 0,
      meetingList: [],
      fields: [
        {
          key: "id",
          label: "ID",
          visible: false,
          thStyle: { textAlign: "center" },
        },
        {
          key: "meetingTitle",
          label: "Tiêu Đề Cuộc Họp",
          visible: true,
          thStyle: { textAlign: "center" },
        },
        {
          key: "fullname",
          label: "Người Tạo Cuộc Họp",
          visible: true,
          thStyle: { textAlign: "center" },
        },
        {
          key: "date",
          label: "Ngày",
          visible: true,
          thStyle: { textAlign: "center" },
        },
        {
          key: "startingHour",
          label: "Bắt Đầu",
          visible: true,
          thStyle: { textAlign: "center" },
        },
        {
          key: "endingHour",
          label: "Kết Thúc",
          visible: true,
          thStyle: { textAlign: "center" },
        },
        {
          key: "departmentId",
          label: "Phòng Ban",
          visible: false,
          thStyle: { textAlign: "center" },
        },
        {
          key: "seats",
          label: "Số Người Tối Đa",
          visible: true,
          thStyle: { textAlign: "center" },
        },
        {
          key: "status",
          label: "Trạng Thái",
          visible: true,
          thStyle: { textAlign: "center" },
        },
        {
          key: "action",
          label: "Thao Tác",
          visible: true,
          thStyle: { textAlign: "center" },
        },
      ],
      bookingInfo: null,
      searchText: "",
      roomSelected: 1,
      statusSelected: "comingUp",
      dateSelected: null,
      dateOptions: [],
      roomOptions: [],
      statusOptions: [
        { value: "comingUp", text: "Sắp diễn ra" },
        { value: "done", text: "Đã xong" },
        { value: "cancel", text: "Đã hủy" },
      ],
      currentPage: 1,
      rows: 0,
      perPage: 7,
      isValid: false,
    };
  },
  props: {
    activeTab: {
      type: Number,
    },
    currentUser: {
      type: Object,
    },
  },
  methods: {
    handleDeleted(row) {
      const newItems = { ...row.item };
      this.bookingInfo = newItems;
    },
    async getBookingInfoList(currentPage) {
      const id_token = parseJwt(localStorage.getItem("id_token"));

      this.userId = id_token.id;
      this.role = id_token.role;

      await ApiService.query("/bookinginfo/getBookinginfoList", {
        params: {
          roomId: parseInt(this.roomSelected),
          status: this.statusSelected,
          page: currentPage || this.currentPage,
          pageSize: this.perPage,
          role: this.currentUser.role,
          userId: this.userId,
          date: this.dateSelected,
        },
      }).then((db) => {
        const { data } = db?.data.data;
        if (this.currentUser.role === "user") {
          const newData = data.map((item) => {
            return {
              ...item,
              id: item.bookingId,
              userId: item["booking.userId"],
              email: item["booking.user.email"],
              endingHour: moment(item["booking.endingTime"]).format("HH:mm"),
              startingHour: moment(item["booking.startingTime"]).format(
                "HH:mm"
              ),
              date: moment(item["booking.startingTime"]).format("DD/MM/YYYY"),
              departmentName: item["booking.department.departmentName"],
              seats: item["booking.room.seats"],
              roomName: item["booking.room.roomName"],
              meetingTitle: item["booking.meetingTitle"],
              status: item["booking.status"],
              fullname: item["booking.user.fullname"],
            };
          });
          this.meetingList = newData;
          this.rows = db.data.data.count;
        } else {
          const newData = data.map((item) => {
            return {
              ...item,
              fullname: item["user.fullname"],
              startingTime: item.startingTime,
              endingHour: moment(item.endingTime).format("HH:mm"),
              startingHour: moment(item.startingTime).format("HH:mm"),
              date: moment(item.endingTime).format("DD/MM/YYYY"),
              departmentName: item["department.departmentName"],
              seats: item["room.seats"],
              roomName: item["room.roomName"],
            };
          });
          this.meetingList = newData;
          this.rows = db.data.data.count;
        }
      });
    },

    async getSearchList(currentPage) {
      return await ApiService.query(`/bookinginfo/searchBookingInfo`, {
        params: {
          fullname: this.searchText,
          roomId: parseInt(this.roomSelected),
          pageSize: this.perPage,
          status: this.statusSelected,
          userId: parseInt(this.currentUser.id),
          page: currentPage || this.currentPage,
          role: this.currentUser.role,
          date: this.dateSelected,
        },
      });
    },

    async search(currentPage) {
      let response = await this.getSearchList(currentPage);

      const { data } = response.data.data;

      if (data.length === 0) {
        response = await this.getSearchList(1);
      }

      if (this.currentUser.role === "user") {
        const searchValue = response.data.data.data.map((item) => {
          return {
            ...item,
            id: item.bookingId,
            userId: item["booking.userId"],
            fullname: item["booking.user.fullname"],
            endingHour: moment(item["booking.endingTime"]).format("HH:mm"),
            startingHour: moment(item["booking.startingTime"]).format("HH:mm"),
            date: moment(item["booking.startingTime"]).format("DD/MM/YYYY"),
            departmentName: item["booking.department.departmentName"],
            seats: item["booking.room.seats"],
            roomName: item["booking.room.roomName"],
            meetingTitle: item["booking.meetingTitle"],
            status: item["booking.status"],
          };
        });

        if (this.searchText.length === 0) {
          this.getBookingInfoList();
        } else {
          this.meetingList = searchValue;
          this.rows = response.data.data.count;
        }
      } else {
        const searchValue = response.data.data.data.map((item) => {
          return {
            ...item,
            fullname: item["user.fullname"],
            endingHour: moment(item.endingTime).format("HH:mm"),
            startingHour: moment(item.startingTime).format("HH:mm"),
            date: moment(item.endingTime).format("DD/MM/YYYY"),
            departmentName: item["department.departmentName"],
            seats: item["room.seats"],
            roomName: item["room.roomName"],
          };
        });

        if (this.searchText.length === 0) {
          this.getBookingInfoList();
        } else {
          this.meetingList = searchValue;
          this.rows = response.data.data.count;
        }
      }
    },
    onInput: debounce(function () {
      this.search();
      this.dateSelected = null;
    }, 500),

    async getRoomList() {
      await ApiService.get("/room/getAllOfRoom").then((db) => {
        const { data } = db.data;
        const newData = data.map((item) => {
          return {
            value: item.id,
            text: item.roomName,
          };
        });
        this.roomOptions = [...newData];
      });
    },

    async getDateList() {
      await ApiService.post("/bookinginfo/getDateList", {
        userId: this.userId,
        status: this.statusSelected,
        roomId: this.roomSelected,
        fullname: this.searchText,
        role: this.role,
      }).then((db) => {
        const { data } = db.data;
        this.dateOptions = data;
      });
    },

    handleChangeSelectRoom() {
      this.currentPage = 1;
      if (this.searchText.length > 0) {
        this.dateSelected = null;
        this.search(1);
      } else {
        this.dateSelected = null;
        this.getBookingInfoList();
      }
    },
    handleChangeSelectStatus() {
      if (this.searchText.length > 0) {
        this.dateSelected = null;
        this.search(1);
      } else {
        this.dateSelected = null;
        this.getBookingInfoList();
      }
    },
    handldeChangeCurrentPage(value) {
      this.currentPage = value;
      if (this.searchText.length > 0) {
        this.dateSelected = null;
        this.search(value);
      } else {
        this.dateSelected = null;
        this.getBookingInfoList(value);
      }
    },
    handleChangeSelectedDate() {
      // this.getBookingInfoList(1);
      if (this.searchText.length > 0) {
        this.search(1);
      } else {
        this.getBookingInfoList(1);
      }
    },
  },

  watch: {
    reloadCount: {
      handler() {
        this.dateSelected = null;
        this.getBookingInfoList();
      },
    },
    currentUser: {
      immediate: true,
      handler() {
        this.getBookingInfoList();
      },
    },
    meetingList() {
      this.getDateList();
    },
  },
  computed: {
    ...mapGetters(["reloadCount"]),
    visibleFilter() {
      return this.fields.filter((item) => item.visible === true);
    },
  },
  components: {
    CancelBookingModal,
  },
  mounted() {
    this.getRoomList();
    // this.handldeChangeCurrentPage();
    moment.locale("vi");
  },
};
</script>
<style scope>
.tooltip {
  opacity: 1;
}
.max-height {
  max-height: 700px;
  height: 650px;
}
.r-25 {
  right: 25px;
  bottom: 14px;
}
</style>
